import React from "react";
import "./chatCard.css";
import App1 from "chatting_screen_sdk";
const ChatCard = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedlang, setSelectedLang] = React.useState("en");
  const [roomData, setRoomData] = React.useState("");

  const roomData1 = {   
   
        receiverDetails: {
          userName: "Alex Carter",
          countryCode: "1",
          mobileNumber: "09876511121",
          userId: "5000",
          email: "Carter@example.com",
          profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
        },

        senderDetails:  {   
          userName: "Ethan Brooks",
          countryCode: "1",
          mobileNumber: "1278787890",
          userId: "5001",
          email: "Brooks@example.com",
          profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
        },
  };

  const roomData2 = {   
   
    senderDetails: {
      userName: "Alex Carter",
      countryCode: "1",
      mobileNumber: "09876511121",
      userId: "5000",
      email: "Carter@example.com",
      profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },

    receiverDetails:  {   
      userName: "Ethan Brooks",
      countryCode: "1",
      mobileNumber: "1278787890",
      userId: "5001",
      email: "Brooks@example.com",
      profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },
  };





  const roomData3 = {
    appKey: "12345",
    senderDetails: {
      userName: "Test 1",
      countryCode: "1",
      mobileNumber: "09876881121",
      userId: "5000147",
      email: "Test 1@example.com",
      profileUrl:  "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1730984611/og2w6ajf1tssginsxhv2.png",
    },

    receiverDetails: {
      userName: "Test 2",
      countryCode: "1",
      mobileNumber: "12787999890",
      userId: "500111148",
      email: "Test 2@example.com",
      profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1730984695/lyfnqbtwlojvo1hwtvn1.jpg",
    },
  };

  const roomData4 = {
    appKey: "12345",
    receiverDetails: {
      userName: "Test 1",
      countryCode: "1",
      mobileNumber: "09876881121",
      userId: "5000147",
      email: "Test 1@example.com",
      profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1730984611/og2w6ajf1tssginsxhv2.png",
    },

    senderDetails: {
      userName: "Test 2",
      countryCode: "1",
      mobileNumber: "12787999890",
      userId: "500111148",
      email: "Test 2@example.com",
      profileUrl:"https://res.cloudinary.com/dpiw7uxv9/image/upload/v1730984695/lyfnqbtwlojvo1hwtvn1.jpg",
    },
  };

  // const roomData5 = {
  //   appKey: "12345",
  //   senderDetails: {
  //     userId: "2006",
  //     userName: "Deepak Rana",
  //     countryCode: "+91",
  //     mobileNumber: "8800165035",
  //     email: "deepak@mailinator.com",
  //     profileUrl:
  //       "https://exobe.s3.af-south-1.amazonaws.com/1728462999321_pexels-photo-1692693.jpeg",
  //   },
  //   receiverDetails: {
  //     userId: "2005",
  //     userName: "Rajat Singh",
  //     countryCode: "+91",
  //     mobileNumber: "9818355991",
  //     email: "rajat@mobiloitte.com",
  //     profileUrl:
  //       "https://exobe.s3.af-south-1.amazonaws.com/1728462876671_HD-wallpaper-mahadev-lord-shiva-shiva-hindu-bhakti-devotional-god-thumbnail.jpg",
  //   },
  // };



  // const roomData6 = {
  //   appKey: "12345",
  //   receiverDetails: {       
  //     userId: "2006",
  //     userName: "Deepak Rana",
  //     countryCode: "+91",
  //     mobileNumber: "8800165035",
  //     email: "deepak@mailinator.com",
  //     profileUrl:
  //       "https://exobe.s3.af-south-1.amazonaws.com/1728462999321_pexels-photo-1692693.jpeg",
  //   },
  //   senderDetails: {
  //     userId: "2005",
  //     userName: "Rajat Singh",
  //     countryCode: "+91",
  //     mobileNumber: "9818355991",
  //     email: "rajat@mobiloitte.com",
  //     profileUrl:
  //       "https://exobe.s3.af-south-1.amazonaws.com/1728462876671_HD-wallpaper-mahadev-lord-shiva-shiva-hindu-bhakti-devotional-god-thumbnail.jpg",
  //   },
  // };

  return (   
    <>
      {open ? (
        <App1 language={selectedlang} appId={'7878'} appKey={"12345"} usersDetails={roomData} />
      ) : (
        <div style={{ display: "flex", alignItems: "center", height: "100vh",flexWrap:'wrap' }}>
          <div className="chat-card">
            <div className="avatar">
              <img
                src={"/users (1).png"}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Alex Carter</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData1);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData1);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>

          <div className="chat-card">
            <div className="avatar">
              <img
                src={"/download (8).jfif"}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Ethan Brooks</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData2);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData2);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>

          <div className="chat-card">
            <div className="avatar">
              <img
                src={"https://res.cloudinary.com/dpiw7uxv9/image/upload/v1730984611/og2w6ajf1tssginsxhv2.png"}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Test2</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData3);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData3);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>

          <div className="chat-card">
            <div className="avatar">
              <img
                src={"https://res.cloudinary.com/dpiw7uxv9/image/upload/v1730984695/lyfnqbtwlojvo1hwtvn1.jpg"}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Test1</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData4);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData4);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>

          {/* <div className="chat-card">
            <div className="avatar">
              <img
                src={""}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Rajat Singh</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData5);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData5);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>

          <div className="chat-card">
            <div className="avatar">
              <img
                src={""}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Deepak Rana</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData6);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData6);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>  */}
        </div>
      )}
    </>
  );
};

export default ChatCard;
