import React, { useEffect, useRef, useState } from "react";
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
  FaPhoneSlash,
  FaPhoneAlt,
} from "react-icons/fa";
import { useVideoChat } from "./Context/VideoChatContext"; // Import the context hook
import  "./VideoChat.css"; // Custom CSS for WhatsApp-like UI
import { Box } from "@mui/system";

import ThumbnailSlider from './ThumbnailSlider'
const VideoChat = ({ CallType,isOnline,chatType }) => {
  const {
    localStream,
    setLocalStream,
    remoteStream,
    setRemoteStream,
    isMuted,
    setIsMuted,
    isVideoOff,
    setIsVideoOff,
    callStarted,
    setCallStarted,
    isRinging,
    setIsRinging,
    isAudioCall,
    setIsAudioCall,
    callId,
    setCallId,
    localVideoRef,
    remoteVideoRef,
    peerConnectionRef,
    iceCandidateQueue,
    setIsCallOpen,
    isCallOpen,
    allOffers,
    setParticipants,
    isGroupCall,
    callUtils,
    remoteStreams,
    socket,
    incallingroom,
    callReceiver,
    incomingCallerName,
    setVideoCall,
    CallStartTime,
    beforeacceptingcall   
  } = useVideoChat();

  const {
    configuration,
    getUserMedia,
    setupPeerConnection,
    handleIncomingCall,
    handleIncomingGroupCall,
    acceptCall,
    rejectCall,
    handleAnswer,
    handleICECandidate,
    startCall,
    toggleMute,
    toggleVideo,
    endCall,
    userid,
    chatRoomid,
    socketRef,
    startGroupCall,
    handleICECandidateGroup,
    handleAnswerGroup,
  } = callUtils;


  // const ringtoneRef = useRef(null);

  // Play ringtone when there's an incoming call
  // useEffect(() => {
  //   if (isRinging && ringtoneRef.current) {
  //     ringtoneRef.current.play();
  //   } else if (ringtoneRef.current) {
  //     ringtoneRef.current.pause();
  //     ringtoneRef.current.currentTime = 0; // Reset to start
  //   }
  // }, [isRinging]);


const handleMedia=async (isAudioCallp)=>{
  let stream=await getUserMedia(isAudioCallp);
  if(stream && beforeacceptingcall.current){
    console.log("beforeacceptingcall.current: ", beforeacceptingcall.current);
    acceptCall()
    beforeacceptingcall.current=null
  }
}
  const [selectedStream, setSelectedStream] = useState(null);
  useEffect(() => {

    // console.log('helloo i am in video caht component')
    // handleMedia(isAudioCall)
     getUserMedia(isAudioCall);
  }, []);

  const [secondsElapsed, setSecondsElapsed] = useState(0);

  // useEffect(()=>{
  //  if(beforeacceptingcall.current && localStream){
  //   acceptCall()
  //   beforeacceptingcall.current=null
  //  }
  // },[localStream])

 

  useEffect(() => {
    if (isCallOpen && !incallingroom ) {
      let startTime=0;
      if(CallStartTime){
         startTime=Math.floor((Date.now()-CallStartTime) / 1000)
      }
      const interval = setInterval(() => {
        setSecondsElapsed(prevSeconds => startTime + prevSeconds + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setSecondsElapsed(0);
    }
  }, [incallingroom]);


  useEffect(()=>{
    

     if(isOnline===true && !callReceiver && incallingroom && chatType !== 'GROUP' ){
      // console.log("useEffect(()=>{: ");
    
      startCall(isAudioCall)
     }
  },[isOnline])

  const [streamsArray,setStreamsArray] = useState([])
  useEffect(()=>{
    let streamsArrays = Array.isArray(remoteStreams) ? remoteStreams : Object.values(remoteStreams);
    console.log("streamsArrays: ", streamsArrays);
    setStreamsArray(streamsArrays)
    if(!selectedStream && streamsArrays.length >0){
      setSelectedStream(streamsArrays[0])
    }
 },[remoteStreams])

 const handleEndCall = ()=>{
  if( isGroupCall.current){
    if(Object.keys(remoteStreams).length>0 ){
    socketRef.current.emit("exit-group-call",{
      userId:userid.current,
      chatRoomId:chatRoomid.current,
      callId:callId
    
    });
  }else{
    socketRef.current.emit("end-call",{
      userId:userid.current,
      chatRoomId:chatRoomid.current,
      callId:callId
    
    });
  }
  }else{
    socketRef.current.emit("end-call",{
      userId:userid.current,
      chatRoomId:chatRoomid.current,
      callId:callId
    
    });
  }

 }
 const sliderRef = useRef(null);

  // useEffect(() => {
  //   const slider = sliderRef.current;
  //   if (slider) {
  //     console.log('i am slider')
  //     const handleWheelScroll = (e) => {
  //       if (e.deltaY !== 0) {
  //         e.preventDefault(); // Prevent vertical scroll
  //         slider.scrollLeft += e.deltaY; // Scroll horizontally
  //       }
  //     };

  //     slider.addEventListener('wheel', handleWheelScroll, { passive: false });

  //     // Cleanup event listener on component unmount
  //     return () => {
  //       slider.removeEventListener('wheel', handleWheelScroll);
  //     };
  //   }
  // }, []);
   
 const renderMainVideo = () => (
   <div className="fullScreenContainer">
     <video
       autoPlay
       ref={(el) => {
         if (el && el.srcObject !== selectedStream) el.srcObject = selectedStream;
       }}
       style={{maxHeight:"60vh"}}
       className="fullScreenVideo"
     />
   </div>
 );
 
//  const renderThumbnailSlider = () => {
//   useHorizontalScroll(sliderRef, 2);
//   return(
//    <div ref={sliderRef} className="thumbnailSlider">
//      {streamsArray.map((stream, index) => (
//        <div
//          key={index}
//          className="thumbnailContainer"
//          onClick={() => setSelectedStream(stream)}
//        >
//        {console.log("stream: dfgf", stream)}
//          <video
//            autoPlay
//            muted
//            style={{display:stream == 'none'?'none':"block"}}
//            ref={(el) => {
//              if (el && el.srcObject !== stream) el.srcObject = stream;
//            }}
//            className="thumbnailVideo"
//          />
//        </div>
//      ))}
//    </div>
// )};

  return (
    <div className="video-chat-container">

{/* <audio ref={ringtoneRef} src="/audio/ringtone.mp3" loop /> */}

      {streamsArray.length >0 &&
      <div className='videoChatContainer' 
      >
{selectedStream && renderMainVideo() }
{<ThumbnailSlider sliderRef={sliderRef} streamsArray={streamsArray} setSelectedStream={setSelectedStream}/>}
    </div>}


     {!isAudioCall && ( <div className="remote-video-container">
        
      <video  ref={remoteVideoRef} autoPlay className='remote-video' />   
       
      </div>
    )}


      {(CallStartTime!=null) && (
      <div style={{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        padding: '10px',
   
      }}>
        <Box style={{ display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',flexDirection:'column'}}>
       <Box sx={{ fontSize: '14px'}}>
      {incomingCallerName}  
        </Box> 
       
        <Box sx={{ fontSize: '12px', color: "#8696A0",}}>
        {Math.floor(secondsElapsed / 60)}:{String(secondsElapsed % 60).padStart(2, '0')}
        </Box>
        </Box>
      </div>
    )}

{!isAudioCall && (<div className="local-video-container">
       
          <video ref={localVideoRef} autoPlay muted className="local-video" />
       
      </div>
    )}


      {(isRinging ) &&
      (
        <div className="incoming-call-popup">
          <p>{incomingCallerName} 
          
          </p>
          <Box sx={{display:'flex',justifyContent:'space-between',
    
    color: 'white', 
    padding: '10px 20px', 
   
  }}>
          <button  className="accept-button" onClick={acceptCall}>
       <FaPhoneAlt color="white" /> 
     </button>
     <button className="reject-button" onClick={rejectCall}>
       <FaPhoneSlash color="white"/> 
     </button>
     </Box>
        </div>
      )}

     
      {callStarted && (
        <div className="call-controls">
          <button onClick={toggleMute} className="control-button" >
            {isMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
          </button>
          {!isAudioCall && (
            <button onClick={toggleVideo}  className="control-button" >
              {isVideoOff ? <FaVideoSlash /> : <FaVideo />}
            </button>
          )}
          <button onClick={()=>{
            handleEndCall()
            endCall();
            }} className="control-button">
            <FaPhoneSlash />
          </button>
        </div>
      )}


    {
      incallingroom && !callReceiver  &&
      (
        <div className="outGoing-call-popup">
          <p>{isOnline===true?'Ringing':'Calling'} 
              <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
            </p>
          
        </div>
      )
    }



      {/* {!callStarted && !isRinging && (
        <div style={{color:'white'}}>
        

        </div>
        // <div className="call-buttons">
        //   <button
        //     onClick={() => {
        //       setIsAudioCall(false);
        //       startCall();
        //     }}
        //   >
        //     <FaVideo /> Start Video Call
        //   </button>
        //   <button
        //     onClick={() => {
        //       setIsAudioCall(true);
        //       startCall();
        //     }}
        //   >
        //     <FaPhoneAlt /> Start Audio Call
        //   </button>
        // </div>
      )} */}
    </div>
  );
};

export default VideoChat;
