import React, { useState, useRef, useEffect } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import WaveSurfer from "wavesurfer.js";
import { Box, IconButton, Typography } from "@mui/material";
import { Delete, PlayArrow } from "@mui/icons-material";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { keyframes } from '@emotion/react';
import toast from "react-hot-toast";

const AudioWaveRecorder = ({ audioUrl, setAudioUrl, setMediaFileInput, mediaFileInput, isAudioRecordingOn, setIsAudioRecordingOn, setMediaType, handleSend, fileType, AppTheme,selectedRoomId,previousRoomId }) => {
  const waveSurferRef = useRef(null);
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();

  const [hasSentAudio, setHasSentAudio] = useState(false);
  const [hasMic, setHasMic] = useState(false);
  const waveAnimation = keyframes`
  0% { color: #b5b0b0; }
  25% { color: #807c7c; }
  50% { color: #595757; }
  75% { color: #403e3e; }
  100% { color: #292727; }
`;



useEffect(()=>{
  if(previousRoomId!=selectedRoomId){
    handleDelete()
  }
},[selectedRoomId])


  useEffect(() => {
    const checkMicrophone = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasAudioInput = devices.some(device => device.kind === "audioinput");
        setHasMic(hasAudioInput);
      } catch (error) {
    
        console.error("Error checking microphone availability", error);
        setHasMic(false);
      }
    };

    checkMicrophone();
  }, [navigator.mediaDevices]);


  

  useEffect(() => {
    if (audioUrl && waveSurferRef.current === null) {
      waveSurferRef.current = WaveSurfer.create({
        container: "#waveform",
        waveColor: "#000",
        progressColor: "#4caf50",
        cursorColor: "#4caf50",
        backend: "WebAudio",
        height: 30,
      });

      waveSurferRef.current.load(audioUrl);
    } else if (!audioUrl && waveSurferRef.current !== null) {
      waveSurferRef.current.destroy();
      waveSurferRef.current = null;
    }
  }, [audioUrl]);
  const [isDelete, setIsDelete] = useState(false)
  const sendAudio = (audioFile) => {
    if (!isDelete) {
      handleSend('AUDIO', null, audioFile);
    }
  }
  const handleAudioRecording = (audioBlob) => {
    if (hasSentAudio) return; // Prevent sending audio if already sent

    const audioFile = new File([audioBlob], "recording.mp3", {
      type: "audio/mp3",
    });

    setIsAudioRecordingOn(false);
    // handleSend('AUDIO', null, audioFile);
    sendAudio(audioFile)
    setHasSentAudio(true); // Mark audio as sent
  };

  const handlePlayPause = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.playPause();
    }
  };

  const handleDelete = () => {
    setAudioUrl(null);
    setIsDelete(true)
    setHasSentAudio(false); // Reset sent status when deleting
    if (waveSurferRef.current) {
      waveSurferRef.current.destroy();
      waveSurferRef.current = null;
    }
    if (isRecording) {
      stopRecording(); // Stop the recording if currently in progress
      setIsAudioRecordingOn(false);
    }
  };

  const recordingOn = () => {
    setHasSentAudio(false); // Reset sent status when starting a new recording
    setIsAudioRecordingOn(true);
    startRecording();
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  

  return (
    <>
      {!isRecording && !audioUrl ? (
        <>
          <IconButton onClick={recordingOn} sx={{ color: AppTheme=='dark'?"#D1D7DB !important":"#3B4A54 !important" }} disabled={!hasMic}>
            <MicIcon />
          </IconButton>
          <Box sx={{ display: "none", }}>
            <AudioRecorder
              onRecordingComplete={handleAudioRecording}
              style={{color:AppTheme==='dark'?"#D1D7DB":'#3B4A54'}}
              audioTrackConstraints={{
                echoCancellation: true,
                noiseSuppression: true,
              }}
              recorderControls={{
                startRecording,
                stopRecording,
                togglePauseResume,
                recordingBlob,
                isRecording,
                isPaused,
                recordingTime,
                mediaRecorder,
              }}
            />
          </Box>

        </>
      ) : (
        <Box
          sx={{
            paddingX: "20px",
            backgroundColor: AppTheme === "dark" ? "#2a3942" : "#f0f2f5",
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "row-reverse",
            color: AppTheme === "dark" ? "#e9edef" : "#3b4a54",
            borderRadius: "8px",
            color:AppTheme==='dark'?"#D1D7DB":'#3B4A54'
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column",color:AppTheme==='dark'?"#D1D7DB !important":'#3B4A54 !important' }}>
            {isRecording ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleDelete}
                sx={{color:AppTheme==='dark'?"#D1D7DB":'#3B4A54'}}
                >
                  <Delete />
                </IconButton>
                <Typography
                  variant="h6"
                  sx={{color:AppTheme==='dark'?"#D1D7DB":'#3B4A54'}}
                >
                  {formatTime(recordingTime)}
                </Typography>
                {/* <IconButton  disabled>
                  <MicIcon />
                </IconButton> */}
                <Typography
                  variant="h6"
                  sx={{
                    animation: `${waveAnimation} 2s infinite`,
                    paddingLeft:"4px"
                  }}
                >
                  •••••••••••••••••
                </Typography>

                {isPaused ? (
                  <IconButton onClick={togglePauseResume}
                  sx={{color:AppTheme==='dark'?"#D1D7DB":'#3B4A54'}}
                  >
                    <MicIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={togglePauseResume}
                  sx={{color:AppTheme==='dark'?"#D1D7DB":'#3B4A54'}}
                  >
                    <PauseCircleOutlineIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    if (isRecording) stopRecording();
                  }}
                  sx={{color:AppTheme==='dark'?"#D1D7DB":'#3B4A54'}}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            ) : (
              audioUrl && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    border: "1px solid #ccc",
                    marginBottom: "5px",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    height: "60px",
                  }}
                >
                  <IconButton onClick={handlePlayPause}>
                    <PlayArrow />
                  </IconButton>
                  <Box id="waveform" sx={{ width: "300px", height: "30px" }}></Box>
                  <IconButton onClick={handleDelete}>
                    <Delete />
                  </IconButton>
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default AudioWaveRecorder;
