import React, { useEffect, useRef, useState, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IoCloseCircleOutline } from "react-icons/io5";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from 'axios'
import '../App.css';
import { ApiConfig } from "../config/apiconfig";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  TextareaAutosize,
  Dialog,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Menu, MenuItem } from "@mui/material";
import MediaPreview from "./MediaPreview";

import {
  openDB,
  addMessageToDB,
  getMessagesFromDB,
  deleteMessageFromDB,
} from "../utility/indexedDB";

import { styled } from "@mui/system";
// import { format } from 'date-fns';
import MessagesList from "./MessagesList";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { hi } from "date-fns/locale/hi"; // Hindi locale
import { ar } from "date-fns/locale/ar"; // Arabic locale
import {
  isToday as dateFnsIsToday,
  isYesterday as dateFnsIsYesterday,
} from "date-fns";
import { IoDocumentText } from "react-icons/io5";
import { FaCamera } from "react-icons/fa";
import { MdPhotoLibrary } from "react-icons/md";
import CameraRecorder from "./Media";
import { IoIosDocument } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import MediaFile from "./MediaFile";
import AddMembersDialog from './AddMembersDialog '
import MicIcon from '@mui/icons-material/Mic';
import AudioRecorder from "./AudioRecorder";
import VideoChat from './VideoChat'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import EmojiPicker from "emoji-picker-react";
import GroupInfoPage from "./GroupInfoPage ";
import VideocamIcon from '@mui/icons-material/Videocam';
import { IoCallOutline } from "react-icons/io5";
import { CiVideoOn } from "react-icons/ci";
import { useVideoChat } from "./Context/VideoChatContext";
import Typing from "./Typing";
import RingingPopup from "./RingingPopup";
import toast from "react-hot-toast";

const StyledTextarea = styled(TextareaAutosize)({
  fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
  width: "100%",
  padding: "8px 12px",
  borderRadius: "8px",

  fontSize: "15px",
  lineHeight: "1.5",
  resize: "none",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#3b4348 transparent",
  border: "none",
  "::placeholder": {
    color: "#8696a0", // Placeholder color
  },
  "&:hover": {
    border: "none",
  },
  "&:focus": {
    border: "none",
    outline: "none",
  },
});




const useStyles = makeStyles((theme) => ({
  mediaMenuIcon: {
    color: "#7F66FF",
    fontSize: "25px",
  },
  mediaMenuTitle: (props) => ({
    fontSize: "16px",
    color: props.AppTheme === "dark" ? "#fff !important" : "#3b4a54 !important",
    marginLeft: "10px",
  }),
}));

export default function ChatScreen({
  appKey,
  open,
  groupIcon,
  setGroupIcon,
  newGroupName,
  setNewGroupName,
  members,
  setGroupMembers,
  groupInfoPage,
  handleClose,
  receiverId,
     receiverName,
  userid,
  setglobalmessage,
  selectedRoomId,
  socketRef,
  setMessages,
  messages,
  db,
  

  AppTheme,
  fontSize,  
  onlineMode,
  profileImg,
  chatType,
  handleCreateGroup,
  handlesetgroupInfoPage,
  handleOpenDialog,
  handleCloseDialog,
  dialogOpen,
  setDialogOpen,
  participants,
  mediaFileInput,
  setMediaFileInput,
  isCameraOn,
  setIsCameraOn,
  setCaption,
  caption,
  changeUser,
  setChangeUser,
  setparticipants,
  setProfileImg,
  setReceiverName,
  chatData,
  setChatData,
  typingStatus,
  setTypingStatus,
  setGroupUsers,
  groupUsers,
  typingInGroup,
  groupAdminName,
  groupCreatedName
}) {
  const [roomId, setRoomId] = useState("");
  const [isFileSend,setIsFileSend] = useState(false)
  const [audioUrl, setAudioUrl] = useState(null);
  const [isAudioRecordingOn, setIsAudioRecordingOn] = useState(false);
  // const [message, setMessage] = useState("");
  // const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMediaSend, setIsLoadingMediaSend] = useState(false);
  const [mediaMessageList, setMediaMessageList] = useState({})
  const [fileTypeDialogOpen, setFileTypeDialogOpen] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedUploadFile, setSelectedUploadFile] = useState(null);
  const [filePreviewDialogOpen, setFilePreviewDialogOpen] = useState(false);
  const [triggerFileInput, setTriggerFileInput] = useState(false);
  const [fileTypeInitialized, setFileTypeInitialized] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [isConnected, setIsConnected] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const documentInputRef = useRef(null);
  const photoVideoInputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const scroll = useRef(true);
  const endMessageRef = useRef(null);
  const [totalPages, setTotalPages] = useState(null);
  const userId = userid;
  const [messagesAdded, setMessagesAdded] = useState(false);
  const typingSRef = useRef(false);
  const [isOnline, setIsOnline] = useState(true);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [mediaType, setMediaType] = useState(null)
  const [fileSize, setFileSize] = useState("")
  const [scrollfalse, setScrollfalse] = useState(true);
  
  const [message, setMessage] = useState("");
  // const classes = useStyles();
  const socketEvent = {
    initiateChat: "initiateUserChat",
    sendMessage: "userSendMessage",
    receiveMessage: "receiveUserMessage",
    userMessage: "getUserMessage",
  };

  






  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const ismdScreenup = useMediaQuery(theme.breakpoints.up("md"));

  const previousRoomId = useRef(null);
  const previousRoomId2 = useRef(null);
  const [usersList, setusersList] = useState(null)
  const {
    localStream,
    setLocalStream,
    remoteStream,
    setRemoteStream,
    isMuted,
    setIsMuted,
    isVideoOff,
    setIsVideoOff,
    callStarted,
    setCallStarted,
    isRinging,
    setIsRinging,
    isAudioCall,
    setIsAudioCall,
    callId,
    setCallId,
    localVideoRef,
    remoteVideoRef,
    peerConnectionRef,
    iceCandidateQueue,
    setIsCallOpen,
    isCallOpen,
    videoCall,
    setVideoCall,
    callUtils,
    setGroupUserName
  } = useVideoChat();   

  const {
    configuration,
    getUserMedia,
    setupPeerConnection,
    handleIncomingCall,
    acceptCall,
    rejectCall,
    handleAnswer,
    handleICECandidate,
    startCall,
    toggleMute,
    toggleVideo,
    endCall,
    startGroupCall,
    chatRoomid
   
    // socketRef,
  } = callUtils;




  const classes = useStyles({ AppTheme });
  const mediaFileInputHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMediaFileInput(file);
      setMediaType("Photo")
      // console.log("Selected file:", file); // You can process the file as needed
      if (file.size > 1048576) {
        let sizeInMb = Math.floor(file.size / (1024 * 1024)) + "MB"
        setFileSize(sizeInMb)
      } else if (file.size > 1024) {
        let sizeInKb = Math.floor(file.size / (1024)) + "KB"
        setFileSize(sizeInKb)
      }
    }
    setAnchorEl(null);
  };


  useEffect(() => {
    if (mediaFileInput?.type.startsWith('image/')) {
      setFileType('IMAGE')
    } else if (mediaFileInput?.type.startsWith('video/')) {
      setFileType('VIDEO')
    } else if (mediaFileInput?.type === 'audio/mp3' || mediaFileInput?.type === 'audio/mpeg') {
      setFileType('AUDIO')
    } else if (mediaFileInput?.type === 'application/pdf') {
      setFileType('FILE')
    } else if (mediaFileInput?.type === 'application/msword' || mediaFileInput?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFileType('FILE')
    } else {
      setFileType('FILE')
    }
  }, [mediaFileInput])


  const documentFileInputHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMediaFileInput(file);
      setMediaType("Document")
      if (file.size > 1048576) {
        let sizeInMb = Math.floor(file.size / (1024 * 1024)) + "MB - " + file.name.split('.').pop().toUpperCase()
        setFileSize(sizeInMb)
      } else if (file.size > 1024) {
        let sizeInKb = Math.floor(file.size / (1024)) + "KB - " + file.name.split('.').pop().toUpperCase()
        setFileSize(sizeInKb)
      }
      // console.log(file.name.split('.').pop().toUpperCase(), "Selected file:", file);
    }
    setAnchorEl(null);
  };

  async function updateMsgStatus(selectedRoomId) {
    try {


      let itemToMove = chatData.map((item) => {
        if (item.chatRoomId === selectedRoomId && item.unreadMessagesCount > 0) {
          item.unreadMessagesCount = item.unreadMessagesCount - 1
        }
        return item
      })
      setChatData(itemToMove)
    } catch (error) {
    }
  }
  
  useEffect(() => {
    
    previousRoomId.current !== selectedRoomId && setMessage("");
    // previousRoomId.current !== selectedRoomId && setMessages("");
    previousRoomId.current !== selectedRoomId && setIsOnline(null);
   
    // Track previous room ID
    if (socketRef.current) {
      setIsConnected(true);
      let roomData;

      // Check if selectedRoomId has changed
      if (previousRoomId.current !== selectedRoomId) {
         
        setPage(1);
        roomData = {
          chatRoomId: selectedRoomId,
          userId: userId,
          page: 1, // When selectedRoomId changes, page = 1
          limit: limit,
        };

        socketRef.current.emit("userJoinChatRoom", roomData);
        // Update previousRoomId to current one
      } else {
        // Only page changed, so we use the current page value
        // scroll.current = false;
        // console.log("// scroll.current = false;: ",);
        
        roomData = {
          chatRoomId: selectedRoomId,
          userId: userId,
          page: page, // Use the actual page value
          limit: limit,   
        };
      }

      page !== 1 &&
        previousRoomId.current === selectedRoomId && 
        socketRef.current.emit("userJoinChatRoom", roomData);
      previousRoomId.current = selectedRoomId;

      socketRef.current.on("userPreviousMessages", (data) => {
        const receivedMessages = data?.messages || [];
      
        setIsOnline(data.isOnline === true ? data.isOnline : data.lastOnline);
        if (previousRoomId2.current == selectedRoomId) {
          // console.log("(previousRoomId2.current ==== selectedRoomId): ");
          scroll.current = false;
          const chatContainer = endMessageRef.current;
          const scrollTopBefore = chatContainer.scrollTop;
          const scrollHeightBefore = chatContainer.scrollHeight;
         setMessages((prevMsg) => [...receivedMessages, ...prevMsg]);
         data.chatRoomId==selectedRoomId && setMessagesAdded({ scrollTopBefore, scrollHeightBefore });
        } else {
          // console.log("(previousRoomId2.current !== selectedRoomId): ");
          data.chatRoomId==selectedRoomId && setMessages(receivedMessages);
         
           previousRoomId2.current = selectedRoomId;
        }

        setTotalPages(data?.totalPages);
        let roomData = chatData.find(item => item.chatRoomId == selectedRoomId)
        // console.log("roomData: rudsj ", roomData);
        if (roomData && roomData.unreadMessagesCount > 0) {
          const unreadMessageIds = receivedMessages    
            .filter(
              (msg) => msg.senderId !== userId && msg.messageStatus !== "READ"
            )
            .map((msg) => msg._id);


          if (unreadMessageIds.length > 0) {
            unreadMessageIds.forEach((messageId) => {

              socketRef.current.emit("userUpdateMessageStatus", {
                chatRoomId: selectedRoomId,
                userId,
                messageId,
                messageStatus: chatType === 'GROUP' ? undefined : "READ",
              });
              updateMsgStatus(selectedRoomId)
            });
          }

        }

        setRoomId(data?.chatRoomId);
        setIsLoading(false);
      });

      socketRef.current.on("userMessageStatusUpdated", (data) => {
        // console.log("Message status updated, sir");
        
        data.chatRoomId === selectedRoomId &&
          setMessages((prevMessageList) =>
            prevMessageList.map((msg) =>
              msg.messageId === data.messageId || msg._id === data.messageId
                ? { ...msg, messageStatus:data.messageStatus }
                : msg
            )
          );
      });

      const handleChatDataStatus = (data) => {
        let chatDataArray = chatData.map(item => {
          if (item.chatRoomId == selectedRoomId) {
            item.lastMessageDetails.messageStatus = data.messageStatus
            item.lastMessageDetails._id = data.messageId
            return item
          } else return item

        });
        if (chatDataArray.length > 0) {
          setChatData(chatDataArray)
        }
      }
      const handleMessage = (data) => {
        setMessages((prevMessageList) => {
          return prevMessageList.map((item) => {

            if (item.messageSentAt == data.messageSentAt) {
              handleChatDataStatus(data)
              return {
                ...item,
                messageId: data.messageId,
                messageStatus: data.messageStatus,
              };
            } else {
              return item;
            }
          });
        });



      };


      socketRef.current.on("getUserMessage", handleMessage);

      // socketRef.current.on("sendersTypingAndOnlineStatus", (data) => {
      //   setTypingStatus(data);
      // });

      socketRef.current.on(socketEvent.receiveMessage, (data) => {
        // console.log("receivemessage si herer");

        // moveIdFirst(data,true)
        // if (data.chatRoomId === selectedRoomId) {
        //   setMessages((prevMessageList) => [...prevMessageList, data]);

        //   socketRef.current.emit("userUpdateMessageStatus", {
        //     chatRoomId: selectedRoomId,
        //     userId,
        //     messageId: data.messageId,
        //     messageStatus:chatType==='GROUP' ? undefined:"READ",
        //   });
        // }
      });

      socketRef.current.on("userDisconnected", (data) => {
        data.userId === receiverId && setIsOnline(data.lastOnline);
      });

      socketRef.current.on("userConnected", (data) => {
        data.userId === receiverId && setIsOnline(true);
      });

      // Cleanup on unmount
      return () => {
        if (socketRef.current) {
          socketRef.current.off("userPreviousMessages");
         
          socketRef.current.off(socketEvent.receiveMessage);
          socketRef.current.off(socketEvent.userMessage);
          socketRef.current.off("userMessageStatusUpdated");
          socketRef.current.off("userDisconnected");
          socketRef.current.off("userConnected");
         
        }
      };
    }
  }, [selectedRoomId, page, chatData]);



  
 


  const handleCameraClick = () => {
    setAnchorEl(false)
    setIsCameraOn(true)
  }
  async function moveIdFirst(data, unreadMessagesCount = false) {
    try {
      let itemToMove = chatData.find(item => item.chatRoomId === data.chatRoomId);
      const remainingItems = chatData.filter(item => item.chatRoomId !== data.chatRoomId);
      itemToMove.lastMessageDetails = data
      setChatData(itemToMove ? [itemToMove, ...remainingItems] : chatData)
    } catch (error) {
    }
  }

  const handleSend = async (fileType, mediaFileInput, AudioFile) => {
    setIsFileSend(false)
    handleDialogClose();
    if (message.trim() !== "" || mediaFileInput || AudioFile) {
      const fileData = mediaFileInput
      setIsCameraOn(false)
      let msgFile
      const messageSentAt = new Date(Date.now()).toISOString()
      let messageContent = message;
      let messageType = "MESSAGE";
      let thumbnail = null
      let fileName = null
      // console.log("we are in ....");

      if (fileData) {

        try {

          fileName = fileData.name
          let thumbnailFile = null
          let currentFiletype = fileData?.type?.split('/')[0]
          if (currentFiletype == 'image' || currentFiletype == 'video') {
            thumbnailFile = await createThumbnail(fileData)

            msgFile = {
              chatRoomId: selectedRoomId,
              content: URL.createObjectURL(fileData),
              messageSentAt: messageSentAt,
              messageStatus: "pending",
              messageType: fileType,
              receiverId: receiverId,
              senderId: userId,
              fileName: fileName,
              thumbnail: thumbnailFile ? URL.createObjectURL(thumbnailFile) : null,
              createdAt: messageSentAt
            };
            setMessages((prevMessageList) => [...prevMessageList, msgFile]);
            setMediaFileInput(null)
            const thumbnailUrl = await uploadFile(thumbnailFile);
            // console.log("thumbnailUrl: ", thumbnailUrl);
            thumbnail = thumbnailUrl
          } else { 

            msgFile = {
              chatRoomId: selectedRoomId,
              content: URL.createObjectURL(fileData),
              messageSentAt: messageSentAt,
              messageStatus: "pending",
              messageType: fileType,
              receiverId: receiverId,
              senderId: userId,
              fileName: fileData.name,
              thumbnail: thumbnailFile ? URL.createObjectURL(thumbnailFile) : null,
              createdAt: messageSentAt,
              fileType: fileData?.type?.split('/')[1]
            };
            setMessages((prevMessageList) => [...prevMessageList, msgFile]);
            setMediaFileInput(null)
          }
          const fileUrl = await uploadFile(fileData);
          // console.log(fileUrl);
          messageContent = fileUrl;
          messageType = fileType

          // if (fileType === "image/*") {
          //   messageContent = fileUrl;
          //   messageType = "IMAGE";
          // } else if (fileType == 'pdf') {
          //   messageContent = fileUrl;
          //   messageType = "FILE";
          // }
          setMediaFileInput(null);
        } catch (error) {
          console.error("File upload failed:", error);
          return;
        }

      } else if (AudioFile) {

        try {
          msgFile = {
            chatRoomId: selectedRoomId,
            content: URL.createObjectURL(AudioFile),
            messageSentAt: messageSentAt,
            messageStatus: "pending",
            messageType: fileType,
            receiverId: receiverId,
            senderId: userId,
            fileName: AudioFile.name,
            createdAt: messageSentAt
          };
          setMessages((prevMessageList) => [...prevMessageList, msgFile]);
          setMediaFileInput(null)
          fileName = AudioFile.name
          const fileUrl = await uploadFile(AudioFile);
          // console.log(fileUrl);
          messageContent = fileUrl;
          messageType = fileType

          // if (fileType === "image/*") {
          //   messageContent = fileUrl;
          //   messageType = "IMAGE";
          // } else if (fileType == 'pdf') {
          //   messageContent = fileUrl;
          //   messageType = "FILE";
          // }
          setMediaFileInput(null);
        } catch (error) {
          console.error("File upload failed:", error);
          return;
        }

      } else {

        msgFile = {
          chatRoomId: selectedRoomId,
          content: messageContent,
          messageSentAt: messageSentAt,
          messageStatus: "pending",
          messageType: messageType,
          senderId: userId,
          fileName: fileName,
          createdAt: messageSentAt,
          thumbnail: thumbnail,
        };
        setMessages((prevMessageList) => [...prevMessageList, msgFile]);
      }
      if (chatType === "ONE_TO_ONE") {
        const newMessage = {
          senderId: userId,
          receiverId: receiverId,
          content: messageContent,
          messageType: messageType,
          chatRoomId: selectedRoomId,
          thumbnail: thumbnail,
          fileName: fileName,
          messageSentAt: messageSentAt
        };
        // console.log("newMessage: ", newMessage);

      setMessage("");
      setSelectedFile(null);
     
      if (socketRef.current && navigator.onLine) {
        // await addMessageToDB(db, storeName, newMessage);
        clearTimeout(clearOut.current);
        if (message.length>0 && socketRef.current) {
          typingSRef.current = false;
          let data = {
            chatRoomId: selectedRoomId,
            userId,
            typingStatus: false,
            OnlineStatus: true,
          };
          socketRef.current.emit("userOnlineAndTypingStatus", data);
        }
        socketRef.current.emit(socketEvent.sendMessage, newMessage);
        moveIdFirst(msgFile)
      } else {
        // Save to IndexedDB if offline
        let msg = {
          chatRoomId: selectedRoomId,
          content: messageContent,
          messageSentAt:messageSentAt,
          messageStatus: "pending",
          messageType: messageType,
          senderId: userId,
          fileName:fileName,
          createdAt:messageSentAt,
          thumbnail:thumbnail,
          receiverId: receiverId,
          chatType:"ONE_TO_ONE"
        };
        // setMessages((prevMessageList) => [...prevMessageList, msg]);
        await addMessageToDB(db, storeName, msg);
        moveIdFirst(msg)
      }
     }else if(chatType==="GROUP"){
      const newMessage = {
        senderId: userId,
        content: messageContent,
        messageType: messageType,
        chatRoomId: selectedRoomId,   
        messageSentAt: messageSentAt,
        thumbnail:thumbnail,
        fileName:fileName,
        messageSentAt: messageSentAt
      };   

      setMessage("");
      setSelectedFile(null);
      if (socketRef.current && navigator.onLine) {
        // await addMessageToDB(db, storeName, newMessage);
        clearTimeout(clearOut.current);
        if (message.length>0 && socketRef.current) {
          typingSRef.current = false;
          let data = {
            chatRoomId: selectedRoomId,
            userId,
            typingStatus: false,
            OnlineStatus: true,
          };
          socketRef.current.emit("userOnlineAndTypingStatus", data);
        }


        socketRef.current.emit('sendMessageInGroup', newMessage,(response) => {
          if (response.error) {
            console.error("Validation failed:", response.error);
          } else {
            // console.log("User validated:", response);
          }
        });
        moveIdFirst(msgFile)
      } else {
        // Save to IndexedDB if offline
      
        // let msg = {
        //   chatRoomId: selectedRoomId,
        //   content: messageContent,
        //   messageSentAt: messageSentAt,
        //   status: "unsent",
        //   messageType: "MESSAGE",
          
        //   senderId: userId,
        // };

    let msg = {
        chatRoomId: selectedRoomId,
        content: messageContent,
        messageSentAt:messageSentAt,
        messageStatus: "pending",
        messageType: messageType,
        senderId: userId,
        fileName:fileName,
        createdAt:messageSentAt,
        thumbnail:thumbnail,
        chatType:"GROUP"
        };

        // setMessages((prevMessageList) => [...prevMessageList, msg]);
        await addMessageToDB(db, storeName, msg);
        moveIdFirst(msg)
      }
     }
     
    }
  };

  const handleAudioRecording = async () => {
    // console.log("we are in ...");
  }


  const uploadFile = async (file) => {

    try {
      const formData = new FormData();
      formData.append("file", file);
      // Upload file logic here
      setIsLoadingMediaSend(true)
      const response = await axios({
        method: "POST",
        
        url: ApiConfig.imageUploadURL,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response && response?.data?.responseCode === 200) {
        // console.log("response: ", response?.data?.result

        // );
        return response?.data?.result
      } else {
        // console.log("else: ", response?.data?.responseMessage);

        // toast.error(
        //   response?.data?.responseMessage || t("unable_to_upload_media"),
        //   { id }
        // );
      }

    } catch (err) {
      console.log("err: ", err);
      // toast.error(
      //   err?.response?.data?.responseMessage ||  t("error_occurred_during_upload"),
      //   { id }
      // );
    } finally {
      setIsLoadingMediaSend(false)
    }


  };
  const [fileModel, setFileModel] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isEmojiOn,setIsEmojiOn] = useState(false)
  const textAreaRef = useRef(null)
  
  useEffect(()=>{
    setIsEmojiOn(false)
  },[selectedRoomId])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFile = () => {
    setAnchorEl(null);
  };
  const handleAttachFile = useCallback(() => {
    setFileTypeDialogOpen(true);
  }, []);

  const changeFileModel = () => {
    // console.log("Changing file model to false");
    setFileModel(false);
  };

  const handleFileTypeSelect = useCallback((type) => {
    setFileType(type);
    setFileTypeDialogOpen(false);
    setTriggerFileInput((prev) => !prev);
    setFileTypeInitialized(true);
  }, []);

  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setMediaFileInput(file);
      setSelectedFile(URL.createObjectURL(file));
      setFilePreviewDialogOpen(true);
      e.target.value = null;
    }
  }, []);

  const scrollToBottom = () => {
    // console.log('group scroll checking on add members')
    messagesEndRef.current?.scrollIntoView({
      behavior: "instant", // Smooth scrolling
      block: "end", // Scroll to the bottom of the element
      inline: "nearest", // Horizontal alignment, keeps it in view without unnecessary scrolling
    });
  };
  useEffect(() => {
    scrollToBottom()
  }, [mediaFileInput])

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSend(fileType);
      e.preventDefault();
    }
  };

  const scrollToLastMessage = () => {
    if (messagesAdded) {
      const chatContainer = endMessageRef.current;
      const scrollHeightAfter = chatContainer.scrollHeight;

      // Restore scroll position after messages are added
      chatContainer.scrollTop =
        scrollHeightAfter -
        messagesAdded.scrollHeightBefore +
        messagesAdded.scrollTopBefore;

      setMessagesAdded(false); // Reset flag after scroll adjustment
    }
  };

  useEffect(() => {
    console.log("scrollfalse && selectedRoomId && scroll?.current: ", scrollfalse, selectedRoomId, scroll?.current);
    if (scrollfalse && selectedRoomId && scroll?.current) {
      scrollToBottom();
    }
    if (scroll?.current === false) {
      scrollToLastMessage();
    }
    setScrollfalse(true)
    scroll.current = true;
  }, [selectedRoomId, messages,scrollfalse]);

 

  useEffect(() => {
    if (fileTypeInitialized && fileType !== null && fileInputRef.current) {
      fileInputRef.current.click();
      setFileTypeInitialized(false);
    }
  }, [fileType, triggerFileInput]);

  const handleDialogClose = () => {
    setFilePreviewDialogOpen(false);
    setSelectedFile(null);
  };

  const dbName = "ChatAppDB";
  const storeName = "unsentMessages";

  const handleChange = React.useCallback(
    (e) => {
      setMessage(e.target.value);
    },
    [setMessage]
  );
  const handleCaptionChange = React.useCallback(
    (e) => {
      setCaption(e.target.value);
    },
    []
  );

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  useEffect(() => {
    // console.log('message.length > 0 && typingSRef.current === false',message)
    if (message.length > 0 && typingSRef.current === false) {
      if (socketRef.current) {
        typingSRef.current = true;
        let data = {
          chatRoomId: selectedRoomId,
          userId,
          typingStatus: true,
          OnlineStatus: true,
        };
        socketRef.current.emit("userOnlineAndTypingStatus", data);
      }
    }

    if (message.length == 0 && typingSRef.current === true) {
      if (socketRef.current) {
        typingSRef.current = false;
        let data = {
          chatRoomId: selectedRoomId,
          userId,
          typingStatus: false,
          OnlineStatus: true,
        };
        socketRef.current.emit("userOnlineAndTypingStatus", data);
      }
    }
  }, [message]);

  const [isKeyboardOn, setIsKeyboardOn] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      // If window height is reduced, the keyboard is likely open
      if (window.innerHeight < windowHeight) {
        setIsKeyboardOn(true);
      } else {
        setIsKeyboardOn(false);
      }
      setWindowHeight(window.innerHeight); // Update current window height
    };

    window.addEventListener('resize', handleResize);
    
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowHeight]);


  const clearOut = useRef();
  useEffect(() => {
    clearTimeout(clearOut.current);

    clearOut.current = setTimeout(() => {
      if (message.length > 0 && socketRef.current) {
        typingSRef.current = false;
        let data = {
          chatRoomId: selectedRoomId,
          userId,
          typingStatus: false,
          OnlineStatus: true,
        };
        socketRef.current.emit("userOnlineAndTypingStatus", data);
      }
    }, 2000)
    return () => clearTimeout(clearOut.current);
  }, [message])



  const handleScroll = (e) => {
    if (e.target.scrollTop == 0) {
      // setIsLoading(true);
      // console.log("setIsLoading(true);: ");
     ( totalPages != 0 && page<totalPages) && setPage((prevPage) => prevPage + 1);
    }
  };

  const convertToArabicNumerals = (number) => {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicDigits[digit] : digit))
      .join("");
  };

  const getArabicTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "صباحاً"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "ظهراً"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "مساءً"; // Evening
    } else {
      return "ليلاً"; // Night
    }
  };

  const formatLastSeenTime = (utcTimestamp) => {

    if(!utcTimestamp)return ;

    const lastSeenDate = new Date(utcTimestamp); // Convert the UTC timestamp to a Date object
    const now = new Date(); // Get current local time

    const timeDiff = now - lastSeenDate;
    const oneDay = 24 * 60 * 60 * 1000;

    const isToday = dateFnsIsToday(lastSeenDate);
    const isYesterday = now - lastSeenDate <= oneDay && !isToday;

    const currentLanguage = i18n.language || "en"; // Default to 'en' if no language is set
    const hours = lastSeenDate.getHours();
    const minutes = lastSeenDate.getMinutes();

    // Determine the locale
    let locale;
    switch (currentLanguage) {
      case "hi":
        locale = hi;
        break;
      case "ar":
        locale = ar;
        break;
      default:
        locale = enUS;
    }

    // Formatting based on language
    if (currentLanguage === "hi") {
      const hindiTimePeriod = getHindiTimePeriod(hours);
      const timeFormatted = format(lastSeenDate, "h:mm", { locale });

      if (isToday) {
        return `आज ${hindiTimePeriod} ${timeFormatted} पर देखा गया`;
      } else if (isYesterday) {
        return `कल ${hindiTimePeriod} ${timeFormatted} पर देखा गया`;
      } else {
        const dayFormatted = format(lastSeenDate, "EEEE", { locale });
        return `${dayFormatted} ${hindiTimePeriod} ${timeFormatted} पर देखा गया`;
      }
    } else if (currentLanguage === "ar") {
      const arabicTimePeriod = getArabicTimePeriod(hours);

      // Convert hours and minutes to Arabic numerals
      const arabicFormattedTime = `${convertToArabicNumerals(
        hours
      )}:${convertToArabicNumerals(minutes)}`;

      if (isToday) {
        return `شوهد اليوم ${arabicTimePeriod} ${arabicFormattedTime}`;
      } else if (isYesterday) {
        return `شوهد أمس ${arabicTimePeriod} ${arabicFormattedTime}`;
      } else {
        const dayFormatted = format(lastSeenDate, "EEEE", { locale });
        return `شوهد يوم ${dayFormatted} ${arabicTimePeriod} ${arabicFormattedTime}`;
      }
    } else {
      // For English, include AM/PM and convert to lowercase
      const timeFormatted = format(lastSeenDate, "h:mm a", { locale }); // AM/PM format
      const lowerCaseTime = timeFormatted.toLowerCase(); // Convert AM/PM to lowercase

      if (isToday) {
        return `Last seen today at ${lowerCaseTime}`;
      } else if (isYesterday) {
        return `Last seen yesterday at ${lowerCaseTime}`;
      } else {
        const dayFormatted = format(lastSeenDate, "EEEE", { locale });
        return `Last seen on ${dayFormatted} at ${lowerCaseTime}`;
      }
    }
  };

  const getHindiTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "सुबह"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "दोपहर"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "शाम"; // Evening
    } else {
      return "रात"; // Night
    }
  };
  // const MediaPreview = React.memo(
  //   ({ mediaFileInput }) => {
  //     const objectURL = React.useMemo(() => {
  //       return URL.createObjectURL(mediaFileInput);
  //     }, [mediaFileInput]);

  //     // React.useEffect(() => {
  //     //   return () => {
  //     //     URL.revokeObjectURL(objectURL);  // Clean up the URL when component unmounts or mediaFileInput changes
  //     //   };
  //     // }, [objectURL]);

  //     return mediaFileInput?.type.startsWith("image/") ? (
  //       <img
  //         src={objectURL}
  //         alt="Media preview"
  //         style={{ maxHeight: "60%", maxWidth: "100%" }}
  //       />
  //     ) : mediaFileInput?.type.startsWith("video/") ? (
  //       <video
  //         src={objectURL}
  //         controls
  //         style={{ maxHeight: "60%", maxWidth: "100%" }}
  //       />
  //     ) : (
  //       <div>Unsupported media type</div>
  //     );
  //   },
  //   (prevProps, nextProps) => {
  //     return prevProps.mediaFileInput === nextProps.mediaFileInput;
  //   }
  // );

  const [isMic, setIsMic] = useState(false)
  const checkMicrophoneAvailability = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAudioInput = devices.some(device => device.kind === "audioinput");
      setIsMic(true)
    } catch (error) {
     
      console.error("Error checking microphone availability:", error);
      setIsMic(false) // Return false if an error occurs
    }
  };

  useEffect(() => {
   checkMicrophoneAvailability()
  }, [])


  const onClose = () => {
    setCaption("")
    setMediaFileInput(null)
  };

  useEffect(() => {
    if (changeUser) {
      onClose()
      setChangeUser(false)
    }
  }, [changeUser])

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const isTypingInArabic = (text) => {
    if (text === "" || text== undefined) return false;

    text = text.trim();
  
    // Check if the first character is in the range of RTL scripts
    if (text.length === 0) return false; // Handle empty strings
  
    const firstChar = text.charCodeAt(0);
    
    // Unicode ranges for common RTL scripts
    const isArabic = (firstChar >= 0x0600 && firstChar <= 0x06FF);
    const isHebrew = (firstChar >= 0x0590 && firstChar <= 0x05FF);
    const isPersian = (firstChar >= 0x0750 && firstChar <= 0x077F); // Additional Persian range
    const isUrdu = (firstChar >= 0x0600 && firstChar <= 0x06FF); // Urdu uses Arabic script
  
    return isArabic || isHebrew || isPersian || isUrdu;

    // if (text === "") return true;
    // // Regex to detect Arabic characters including extended ranges
    // const arabicRegex =
    //   /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    // return arabicRegex.test(text);
  };

useEffect(()=>{
 return  ()=> {
  let data = {
    chatRoomId: selectedRoomId,
    userId,
    typingStatus: false,
    OnlineStatus: true,
  };
  if (socketRef.current) {
  socketRef.current.emit("userOnlineAndTypingStatus", data);
  }
}
},[selectedRoomId])


  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const handleCheckboxChange = (event) => {
    const userId = event.target.value;
    // console.log('jsiodjfwjhe9f', userId)
    // Check if the userId is already selected
    if (selectedUserIds.includes(userId)) {
      // If the user is already selected, remove them from the array
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
    } else {
      // Otherwise, add the user to the array
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };




  const createThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const fileType = file.type.split('/')[0]; // Get the type: image or video
      const reader = new FileReader();

      reader.onload = (event) => {
        const url = event.target.result;

        if (fileType === 'image') {
          // Handle image
          const img = new Image();
          img.src = url;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas dimensions to match the original image (30% scale)
            canvas.width = img.width * 0.1;
            canvas.height = img.height * 0.1;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert canvas content to a Blob (low-quality image)
            canvas.toBlob((blob) => {
              if (blob) {
                const thumbnailFile = new File([blob], `thumbnail_${file.name}`, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                });
                resolve(thumbnailFile);
              } else {
                reject(new Error('Failed to create thumbnail Blob'));
              }
            }, 'image/jpeg', 0); // Set quality to 0 for the lowest quality
          };
        } else if (fileType === 'video') {
          // Handle video
          const video = document.createElement('video');
          video.src = url;
          video.crossOrigin = 'anonymous';

          // Wait for the video metadata to be loaded
          video.onloadedmetadata = () => {
            // Capture the thumbnail at the 1st second, but ensure the video is ready
            video.currentTime = Math.min(1, video.duration - 1);
          };

          video.onseeked = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas dimensions to match the original video
            canvas.width = video.videoWidth; // Adjust scale as needed
            canvas.height = video.videoHeight;

            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convert canvas content to a Blob (low-quality image)
            canvas.toBlob((blob) => {
              if (blob) {
                const thumbnailFile = new File([blob], `thumbnail_${file.name}.jpeg`, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                });
                resolve(thumbnailFile);
              } else {
                reject(new Error('Failed to create thumbnail Blob from video'));
              }
            }, 'image/jpeg', 0.1); // Adjust quality if needed
          };

          video.onerror = reject; // Handle video load errors
        }
      };

      reader.onerror = reject; // Handle file read errors
      reader.readAsDataURL(file);
    });
  };



  // useEffect(() => {
  //   if (socketRef.current) {


  //     socketRef.current.on('groupChatDetails', (data) => {
  //       setScrollfalse(false);
  //       setReceiverName(data?.groupDetails?.groupName);

  //       setProfileImg(data?.groupDetails?.imageUrl);

  //       setparticipants(data?.groupDetails.participants)


  //     })
  //   }
  //   return () => {
  //     if (socketRef.current)
  //       socketRef.current.off('groupChatDetails')
  //   }
  // }, [socketRef?.current])





  
  
  


  return (
    <>
      {/* FileTypeDialog and FilePreviewDialog */}
      <input
        ref={fileInputRef}
        id="file-input"
        type="file"
        style={{ display: "none" }}
        accept={fileType}
        onChange={handleFileChange}
      />

  
{groupInfoPage && isSmScreen ?

<>

<GroupInfoPage
  userId={userId}
  AppTheme={AppTheme}
  handlesetgroupInfoPage={handlesetgroupInfoPage}
  handleOpenDialog={handleOpenDialog}
  onClose={handleCloseDialog}
  handleCloseDialog={handleCloseDialog}
  socketRef={socketRef}
  chatRoomId={selectedRoomId}
  groupIcon={groupIcon}
  setGroupIcon={setGroupIcon}
  newGroupName={newGroupName}
  setNewGroupName={setNewGroupName}
  members={members}
  setGroupMembers={setGroupMembers}
/>

</>

:
     videoCall
    ?
     <VideoChat socketRef={socketRef} userid={userId}  chatRoomid={selectedRoomId} isOnline={isOnline} chatType={chatType}/>
      :
   <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "100vw",
          margin: "auto",
          height: "100vh",
          boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.06)",
          borderRadius: "0px",
          overflow: "hidden",
          position: "relative",
        }}
        elevation={3}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            maxWidth: "100vw",
            margin: "auto",
            height: "100%",
            // borderRadius: "10px",
            overflow: "hidden",
            position: "relative",
            backgroundColor:AppTheme === "dark" ? "#0b141a" : "#efeae2",
            color: "white",
          }}
        >
       <Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 16px",
    backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
    color: "white",
    width: '100%',
    boxSizing: 'border-box',
    gap:'15px',
    position: 'relative'
  }}
>
  <Box display={"flex"} gap={"10px"} sx={{ flexGrow: 1, minWidth: 0, cursor:chatType==='GROUP'?'pointer':'inherit' }} onClick={()=>chatType==='GROUP'  && handlesetgroupInfoPage(true)}>
    <Avatar src={profileImg} sx={{ width: 40, height: 40 }} />
   
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      sx={{ minWidth: 0,width:'100%' }} // Ensure the box shrinks correctly
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: AppTheme === "light" ? "#111b21" : "#e9edef",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          minWidth: 0, // Allow content to shrink
          width:'70%',
          direction:  (isTypingInArabic(receiverName) === true) ? 'rtl' : 'ltr',
        
          textAlign:  i18n.language === "en"?'left':'right',
          
        }}
      >
        {
          receiverName ||
          ""}
         
      </Typography>

   
     



   {
    chatType=== "ONE_TO_ONE"?
     chatData.filter((item)=>item.chatRoomId == selectedRoomId ).length>0 && chatData.filter((item)=>item.chatRoomId == selectedRoomId )[0]?.userDetails?.isTyping==true ?
    
     <Typography
            variant="body1"
            sx={{
              fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "1.5",
              letterSpacing: "0.00938em",
              color: "#8696a0",
            }}
          >
            { t("typing")}
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </Typography>
          :
          isOnline === true?
                     <Typography
                        variant="body1"
                        sx={{
                          fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "1.5",
                          letterSpacing: "0.00938em",
                          color: "#8696a0",
                        }}
                      >
                        {t("online")}
                      </Typography>
                      :
                      <Typography
                      variant="body1"
                      sx={{
                        fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
                        fontWeight: "400",
                        fontSize: "13px",
                        lineHeight: "1.5",
                        letterSpacing: "0.00938em",
                        color: "#8696a0",
                      }}
                    >
                      {formatLastSeenTime(isOnline)}
                    </Typography>

    :
    chatData.filter((item)=>item.chatRoomId == selectedRoomId ).length>0 && chatData.filter((item)=>item.chatRoomId == selectedRoomId )[0]?.isTyping!='' ?
    <Typography
    variant="body1"
    sx={{
      fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      color: "#8696a0",
    }}
  >
  
   
  <>
             { chatData.filter((item)=>item.chatRoomId == selectedRoomId ).length>0 && chatData.filter((item)=>item.chatRoomId == selectedRoomId )[0]?.isTyping}
              </> 
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>  
  </Typography>
    :
    <Box
    sx={{ width: '100%' }}
  >

    <Typography
      variant="body1"
      sx={{
        fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "1.5",
        letterSpacing: "0.00938em",
        color: "#8696a0",
        maxWidth: "70%",
        whiteSpace: "nowrap",       // Ensure text stays on one line
        overflow: "hidden",         // Hide overflowing text
        textOverflow: "ellipsis",
        direction:(isTypingInArabic() === true) ? 'rtl' : 'ltr',

        textAlign:  i18n.language === "en"?'left':'right',
      }}
    >
      {groupUsers.map((item, index) => {
        if (index == 0) {
          return item
        } else {
          return "," + item
        }
      })}
    </Typography>
  </Box>

   }






    </Box>





  </Box>


  <Box sx={{color:AppTheme=='dark'?'#8696A0':'#8696A0',
  display:"flex",
    alignItems:"center",
    fontSize:"20px",
    gap:"15px"
  }}>
    <IoCallOutline sx={{ color: "#8696a0" }}  onClick={()=>{
      if(navigator.onLine){
      callUtils.chatRoomid.current = selectedRoomId;
      setVideoCall(true)
      chatType=='GROUP'? startGroupCall():startCall(true)
      
      }
      // startGroupCall()
      // startCall()
     } }/>
    <CiVideoOn 
      onClick={()=>{
        if(navigator.onLine){
      callUtils.chatRoomid.current = selectedRoomId;
      setVideoCall(true)
      chatType=='GROUP'? startGroupCall():startCall(false)
      chatType=='GROUP' && setGroupUserName(receiverName)  
        }
     } }
     />
    
      <CloseIcon  onClick={() => {
        handleClose(null);
      }}/>
   
  </Box>
</Box>  

{isRinging && (
  
  <RingingPopup
    callerName="John Doe" // Replace with dynamic caller name
    onAccept={acceptCall}
    onReject={rejectCall}
    setVideoCall={setVideoCall}
    videoCall={videoCall}
  />
  
)}




            {mediaFileInput && !isCameraOn && !isLoadingMediaSend ? (
              <>
                <MediaFile
                  mediaFileInput={mediaFileInput}
                  AppTheme={AppTheme}
                  handleSend={handleSend}
                  fileType={fileType}
                  onClose={onClose}
                  mediaType={mediaType}
                  fileSize={fileSize}
                  MediaPreview={MediaPreview}
                  setMediaFileInput={setMediaFileInput}
                  isFileSend={isFileSend}
                  setIsFileSend={setIsFileSend}
                
                />

              </>
            ) : isCameraOn && !isLoadingMediaSend ? (
              <CameraRecorder
                isCameraOn={isCameraOn}
                setIsCameraOn={setIsCameraOn}
                AppTheme={AppTheme}
                StyledTextarea={StyledTextarea}
                caption={caption}
                i18n={i18n}
                isTypingInArabic={isTypingInArabic}
                message={message}
                handleCaptionChange={handleCaptionChange}
                handleSend={handleSend}
                fileType={fileType}
                setMediaFileInput={setMediaFileInput}
                mediaFileInput={mediaFileInput}
                setCaption={setCaption}
                changeUser={changeUser}

            />
          ) : (
            <MessagesList
              messages={messages}
              userId={userId}
              AppTheme={AppTheme}
              fontSize={fontSize}
              handleScroll={handleScroll}
              messagesEndRef={messagesEndRef}
              ismdScreenup={ismdScreenup}
              endMessageRef={endMessageRef}
              selectedRoomId={selectedRoomId}
              onlineMode={onlineMode}
              chatType={chatType}
              handleCreateGroup={handleCreateGroup}
            handleOpenDialog={handleOpenDialog}
            handlesetgroupInfoPage={handlesetgroupInfoPage}
            page={page}
            totalPages={totalPages}
              participants={participants}
              profileImg={profileImg}
              groupAdminName={groupAdminName}
              previousRoomId={previousRoomId}
              
          />
          )}

            {(mediaFileInput || isCameraOn) && !isLoadingMediaSend ? (
              <></>
            ) : (
            <Box
            sx={{
              display: "flex",
              flexDirection:'column-reverse'
            }}
            >
            
  
  {isEmojiOn && <EmojiPicker  onEmojiClick={(emoji)=>{
       setMessage((preMessage)=>preMessage+emoji.emoji)
      }}  width={'100%'} height={"400px"} searchDisabled	/>}
    
     
      <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "14px 16px",
                  // borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: AppTheme === "dark" ? "#202c33" : "#ffffff",
                }}
              >
             {  !isAudioRecordingOn &&
                <IconButton
                  onClick={(event) => {
                    textAreaRef.current?.blur()
                    setIsEmojiOn((preData)=>!preData)
                  }}
                  sx={{ color: "#8696a0" }}
                >
                 {!isEmojiOn?  <SentimentSatisfiedAltIcon />:
                  <CloseIcon />}
                </IconButton>}


             {   !isAudioRecordingOn &&<IconButton
                  onClick={(event) => {
                    handleAttachFile();
                    setAnchorEl(event.currentTarget);
                    setFileModel(!fileModel);
                  }}
                  sx={{ color: "#8696a0" }}
                >
                  <AttachFileIcon />
                </IconButton>}

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseFile}
                  PaperProps={{
                    sx: {
                      boxShadow: "none !important", // Removes default shadow
                      transform: "translateY(-55px) !important",
                      width: "180px !important",
                      padding: "10px 0px !important",
                      backdropFilter: "none !important",
                      backgroundColor: AppTheme === "dark" ? "#182229 !important" : "#fff !important", // Ensure background color overrides MUI default
                      // Ensure background color overrides MUI default
                    },
                  }}
                >
                  <MenuItem onClick={() => {
                    documentInputRef.current.click()
                    setAnchorEl(null)
                  }}>
                    <Box className={classes.mediaMenuIcon}>
                      <IoDocumentText />
                    </Box>
                    <Box className={classes.mediaMenuTitle}>Document</Box>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    photoVideoInputRef.current.click()
                    setAnchorEl(null)
                  }}>
                    <Box className={classes.mediaMenuIcon}>
                      <MdPhotoLibrary />
                    </Box>
                    <Box className={classes.mediaMenuTitle}>Photo & Video</Box>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleCameraClick()
                    setAnchorEl(null)
                  }}>
                    <Box className={classes.mediaMenuIcon}>
                      <FaCamera />
                    </Box>
                    <Box className={classes.mediaMenuTitle}>Camera</Box>
                  </MenuItem>
                </Menu>

                <input
                  type="file"
                  ref={documentInputRef}
                  style={{ display: "none" }}
                  onChange={documentFileInputHandler}
                />
                <input
                  type="file"
                  ref={photoVideoInputRef}
                  style={{ display: "none" }}
                  accept="image/*,video/*"
                  onChange={mediaFileInputHandler}
                />
                <input
                  type="file"
                  ref={cameraInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  capture="environment"
                />

                {!audioUrl && !isAudioRecordingOn &&
                  

                  <Typing AppTheme={AppTheme} textAreaRef={textAreaRef} message={message} handleKeyPress={handleKeyPress} handleChange={handleChange}  setIsEmojiOn={setIsEmojiOn}/>
                  }

                {message.length > 0 ? <IconButton
                  onClick={() => handleSend(fileType)}
                  sx={{ color: "#8696a0" }}
                  disabled={!message.length > 0}
                >
                  <SendOutlinedIcon />
                </IconButton> :

                  < AudioRecorder
                    audioUrl={audioUrl}
                    setAudioUrl={setAudioUrl}
                    setMediaFileInput={setMediaFileInput}
                    mediaFileInput={mediaFileInput}
                    isAudioRecordingOn={isAudioRecordingOn}
                    setIsAudioRecordingOn={setIsAudioRecordingOn}
                    setMediaType={setMediaType}
                    handleSend={handleSend}
                    fileType={fileType}
                    AppTheme={AppTheme}
                    isMic={isMic}
                    selectedRoomId={selectedRoomId}
                    previousRoomId={previousRoomId}
                  />}
              </Box>

              </Box>
            )}
          </Box>
        </Paper>
      

}


      {dialogOpen && <AddMembersDialog
        open={dialogOpen}
        onClose={handleCloseDialog}

       appKey={appKey}
        AppTheme={AppTheme}
        socketRef={socketRef}
        selectedRoomId={selectedRoomId}
        userId={userId}
        handleCheckboxChange={handleCheckboxChange}
        selectedUserIds={selectedUserIds}
        setSelectedUserIds={setSelectedUserIds}

      />}
    </>
  );
}
