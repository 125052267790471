import React, { useRef } from "react";
import useHorizontalScroll from "./useHorizontalScroll ";

 const ThumbnailSlider = ({sliderRef,streamsArray,setSelectedStream}) => {
  
  useHorizontalScroll(sliderRef, 2); // Optional speed multiplier

  return (
    <div ref={sliderRef} className="thumbnailSlider">
    {streamsArray.map((stream, index) => (
      <div
        key={index}
        className="thumbnailContainer"
        onClick={() => setSelectedStream(stream)}
      >
      {console.log("stream: dfgf", stream)}
        <video
          autoPlay
          muted
          style={{display:stream == 'none'?'none':"block"}}
          ref={(el) => {
            if (el && el.srcObject !== stream) el.srcObject = stream;
          }}
          className="thumbnailVideo"
        />
      </div>
    ))}
  </div>
  );
};

export default ThumbnailSlider;
