import React from "react";
import ReactDOM from "react-dom/client";
import  "./index.css";
import App1 from "./App";
import reportWebVitals from "./reportWebVitals";
export default App1;
// const root = ReactDOM.createRoot(document.getElementById('root'));
// // // The render method displays the App component within the root DOM node.
// root.render(
//   // <React.StrictMode>
//     <App1 language={'en'} appId={'7878'} appKey={"12345"} usersDetails={{
       
//         receiverDetails: {
//           userName: "Alex Carter",
//           countryCode: "1",
//           mobileNumber: "09876511121",
//           userId: "5000",
//           email: "Carter@example.com",
//           profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
//         },

//         senderDetails:  {   
//           userName: "Ethan Brooks",
//           countryCode: "1",
//           mobileNumber: "1278787890",
//           userId: "5001",
//           email: "Brooks@example.com",
//           profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
//         },
//       }}/>
//   // </React.StrictMode>
// );

//  Optional: This is used for measuring the performance of the app.
reportWebVitals();
