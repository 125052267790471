import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Paper,
  TextField,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  Button,
} from "@mui/material";
import { Edit, Check, CameraAlt, Close, PersonAdd } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import axios from "axios";
import { hi, enUS, ar } from "date-fns/locale";
import { format, isToday, isThisWeek, isYesterday } from "date-fns";
import LogoutIcon from '@mui/icons-material/Logout';
// import { IoIosArrowDropdownCircle } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import ExitGroupDialog from "./exitGroupDialog";
import { ApiConfig } from "../config/apiconfig";
const GroupInfoPage = ({ groupCreationDate,appKey,groupIcon, setGroupIcon, newGroupName, setNewGroupName, members, setGroupMembers, userId, handlesetgroupInfoPage, AppTheme, handleOpenDialog, chatRoomId, socketRef, onClose,groupAdminName }) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const { t, i18n } = useTranslation();
  const [profileHandling, setProfileHandling] = useState(false);
  const profileImgUrl = useRef(null);
  const [groupPage, setGroupPage] = useState(null)
  const [adminName, setAdminName] = useState('')
  const updateinfo = useRef(false);
  const [isActiveMember, setActiveMember] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId,setSelectedId]= useState(null)
  const [isAdmin,setIsAdmin]=useState(false)
  const [exitGroupDialog,setExitGroupDialog] = useState(false)
  const firstRender=useRef(null)
  const handleNameEditToggle = () => {

    setIsEditing(!isEditing);
  };

  const handleNameChange = (e) => {
    setNewGroupName(e.target.value);
  };

useEffect(()=>{
  if(firstRender.current || chatRoomId===null){
     handlesetgroupInfoPage(false)
  }
  firstRender.current=1;
  return ()=>{
    firstRender.current=null;
  }
},[chatRoomId])

useEffect(()=>{
  members.map((item)=>{
    if(item.userId == userId ){
      if(item.userRole == 'ADMIN'){
        setIsAdmin(true)
      }else{
        setIsAdmin(false)
      }
    }
  })
},[members])

  const handleNameSubmit = () => {
    updateinfo.current = true
    setIsEditing(false);


    let roomData = {
      "appKey": appKey,
      chatRoomId: chatRoomId,
      "groupName": newGroupName.trim(),
      'imageUrl': groupIcon,
      // 'userDetails':userObjects
    }
    socketRef.current.emit('initiateGroupChatRoom', roomData, (response) => {
      if (response.error) {
        if (response.success === false) {
          toast.error(response.message)
        }
        console.error("Validation failed:", response.error);
      } else {
        if (response.success === true) {

          // toast.success(response.message)
        }
        // console.log("User validated:", response);
      }
    })

    // console.log("Updated Group Name:", newGroupName);
  };

  const handleExistGroup = () => {
    if (socketRef?.current && isActiveMember) {
      socketRef.current.emit('exitGroupChatRoom', { chatRoomId: chatRoomId, userId: userId, removedBy: userId }, (response) => {
        // console.log("exit response: ", response);
        if (response && response.success) {
          setActiveMember(false)
        }

      })
    }
  }

  const RemoveFromGroup = (chatRoomId,userId,removeBy) => {
    if (socketRef?.current && isActiveMember) {
      setAnchorEl(null)
      socketRef.current.emit('exitGroupChatRoom', { chatRoomId: chatRoomId, userId: userId, removedBy: removeBy }, (response) => {
        // console.log("exit response: ", response);
        if (response && response.success) {
          // setActiveMember(false)
        }

      })
    }
  }
  const makeGroupAdmin = (newAdminId) => {
    
    setAnchorEl(null)
    
     if(socketRef.current){
      socketRef.current.emit('updateUserRoleOfGroupUser', {chatRoomId: chatRoomId, userId :newAdminId?.userId , userRole:newAdminId?.userRole=='ADMIN'?'USER':"ADMIN"}, (response) => {
        if(response.success){
          setGroupMembers(members.map((item)=>{
            if(item.userId == response.updatedUser.userId){
              item.userRole = response.updatedUser.userRole
            }
            return item
            
          }))
        }
       
        // console.log("User validated:dfddsdfs", response);
      })
     }
  }

  const handleImageUpload = async (e) => {
    if (!profileHandling) {
      const file = e.target?.files[0];
      setProfileHandling(true);

      const formData = new FormData();
      const id = toast.loading(t("uploading_media"));
      formData.append("file", file);

      try {
        const response = await axios({
          method: "POST",
          url:ApiConfig.imageUploadURL,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response && response?.data?.responseCode === 200) {
          profileImgUrl.current = response?.data?.result;
          setGroupIcon(profileImgUrl.current);
          toast.success(t("media_uploaded_successfully"), { id });
          let roomData = {
            "appKey": appKey,
            chatRoomId: chatRoomId,
            // "groupName":newGroupName.trim(),
            'imageUrl': profileImgUrl.current,
            // 'userDetails':userObjects
          }
          socketRef.current.emit('initiateGroupChatRoom', roomData, (response) => {
            if (response.error) {
              if (response.success === false) {
                toast.error(response.message)
              }
              console.error("Validation failed:", response.error);
            } else {
              if (response.success === true) {

                // toast.success(response.message)
              }
              // console.log("User validated:", response);
            }
          })
        } else {
          toast.error(
            response?.data?.responseMessage || t("unable_to_upload_media"),
            { id }
          );
        }

      } catch (err) {
        toast.error(
          err?.response?.data?.responseMessage || t("error_occurred_during_upload"),
          { id }
        );
      } finally {

        setProfileHandling(false);
      }
    }
  };



  const isDarkTheme = AppTheme === 'dark';
  const isTypingInArabic = (text) => {
    if (text === "") return true;
    // Regex to detect Arabic characters including extended ranges
    const arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    return arabicRegex.test(text);
  };

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.emit('getGroupChatDetails', { chatRoomId: chatRoomId }, (response) => {
        if (response.error) {
          console.error("Validation failed:", response.error);
        } else {
          // console.log("User validated:", response);
        }
      });


    }

  }, [])

  // const handlegroupchatDataevent=(data)=>{
  //   console.log("groupChatDetails: ", data);
  //    setGroupPage(data.groupDetails)

  //  }

  // useEffect(()=>{
  //  if(groupPage){
  //   setGroupIcon(groupPage.imageUrl)
  //   setNewGroupName(groupPage.groupName)
  //   setGroupMembers(groupPage.participants)


  //  }
  // },[groupPage])

  // useEffect(() => {
  //   if (members.length > 0) {
  //     const adminUser = members.find(member => member.userId === groupAdminId)
  //     adminUser?.userId === userId ? setAdminName('') : setAdminName(adminUser?.userName);
  //   }
  // }, [members])


  const getHindiTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "सुबह"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "दोपहर"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "शाम"; // Evening
    } else {
      return "रात"; // Night
    }
  };

  // Utility function to convert numbers to Arabic numerals
  const convertToArabicNumerals = (number) => {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicDigits[digit] : digit))
      .join("");
  };

  const getArabicTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "صباحًا"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "ظهرًا"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "مساءً"; // Evening
    } else {
      return "ليلًا"; // Night
    }
  };

  const formatTimestamp = (timestamp) => {
    console.log('timestampjdlfkjdlfjsl',timestamp)
    if (!timestamp) return;
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    // Determine the user's current language
    const currentLanguage = i18n.language || "en"; // Default to 'en' if no language is set

    // Select locale based on the language
    const locale =
      currentLanguage === "hi" ? hi : currentLanguage === "ar" ? ar : enUS; // Arabic Saudi Arabia locale for 'ar'


    const isToday = date.toDateString() === today.toDateString()
    const isYesterday = date.toDateString() === yesterday.toDateString()

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();

    // Format date and time with Arabic numerals if language is Arabic
    if (currentLanguage === "ar") {

      const formattedDate = `${convertToArabicNumerals(
        day
      )}/${convertToArabicNumerals(month)}/${convertToArabicNumerals(
        year
      )}`;
      const timePeriod = getArabicTimePeriod(hours);
      return formattedDate;
    } else if (currentLanguage === "hi") {
      const formattedDate = format(date, "d/M/yyyy", { locale });
      const timePeriod = getHindiTimePeriod(hours);
      return formattedDate;
    } else {
      // For English or other languages
      const formattedDate = format(date, "d/M/yyyy", { locale });
      const amPm = hours < 12 ? "AM" : "PM";
      return formattedDate;
    }
  };



  return (
    <Paper
      elevation={2}
      sx={{
        boxSizing: 'border-box',
        height: '100%',
        position: 'absolute',
        padding: "24px",
        backgroundColor: isDarkTheme ? '#111b21' : '#ffffff',
        color: isDarkTheme ? '#e0e0e0' : theme.palette.text.primary,
        width: "100%",
        borderRadius: '0px',
        // display: "flex", // Ensure the layout takes up full height
        // flexDirection: "column",
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={() => handlesetgroupInfoPage(false)}
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 1,
           color: "#8696a0" 
        }}
      >
        <Close />
      </IconButton>

      {/* Group Profile Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box sx={{ position: "relative", display: "inline-block" }}>
          <Avatar
            src={groupIcon}

            sx={{
              width: 120,
              height: 120,
              [theme.breakpoints.down("sm")]: {
                width: 80,
                height: 80,
              },
            }}
          />
          <input
            accept="image/*"
            id="avatar-upload"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <label htmlFor="avatar-upload">
            <IconButton
              component="span"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                backgroundColor: isDarkTheme ? '#333' : "white",
                color: isDarkTheme ? '#e0e0e0' : '#000',
                "&:hover": {
                  backgroundColor: isDarkTheme ? '#555' : "#f0f0f0",
                },
              }}
            >
              <CameraAlt />
            </IconButton>
          </label>
        </Box>
        {isEditing ? (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2, width: '100%' }}>

            <TextField
              multiline
              value={newGroupName}
              onChange={handleNameChange}
              variant="standard"
              fullWidth
              sx={{
                textAlign:
                  i18n.language === "ar" && isTypingInArabic(newGroupName) ? "right" : "left", // Align text based on language
                direction:
                  i18n.language === "ar" && isTypingInArabic(newGroupName) ? "rtl" : "ltr",
                maxWidth: 300, // Set the same maxWidth for consistency
                '& .MuiInput-underline:before': {
                  borderBottomColor: AppTheme === 'dark' ? 'rgba(192, 192, 192, 0.623)' : '#f0f2f5',
                  borderBottomWidth: '2px',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottomColor: '#00A884'
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#00A884'
                },
                '& .MuiInputLabel-root': {
                  color: '#8696a0',
                  fontSize: '14px',
                  fontWeight: 600,
                },
                '& .MuiInputBase-root': {
                  color: AppTheme === 'dark' ? '#e9edef' : '#111B21',
                },
                '& .MuiInputBase-input': {
                  fontSize: '24px', // Set the font size to 24px
                  lineHeight: '1.5', // Set the line height (adjust as needed)
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleNameSubmit} sx={{ color: isDarkTheme ? '#e0e0e0' : '#000' }}>
                    <Check />
                  </IconButton>
                ),
              }}
              inputProps={{
                maxLength: 100, // Set the maximum length of the input
              }}
            />




          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography sx={{
              color: isDarkTheme ? '#e0e0e0' : theme.palette.text.primary, fontSize: '24px', textAlign: "center", lineHeight: "30px",
              wordBreak: "break-word",
            }}>
              {newGroupName}
            </Typography>
            <IconButton onClick={handleNameEditToggle} sx={{ color: isDarkTheme ? '#e0e0e0' : '#000' }}>
              <Edit />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Group Details Section */}
      <Box
        sx={{
          mb: 3,
          // backgroundColor: isDarkTheme ? '#222831' : theme.palette.background.default,
          borderRadius: "8px",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: "8px",

        }}
      >
        <Typography sx={{
          color: isDarkTheme ? '#a3a3a3' : "#667781", fontSize: '14px', wordBreak: "break-word", display: 'flex',
          textAlign: "center",
        }}>
          {t('groupCreated', { user: groupAdminName || t('you') })} {formatTimestamp(groupCreationDate)}

        </Typography>
        <Typography sx={{ color: isDarkTheme ? '#a3a3a3' : "#667781", fontSize: '14px' }}>
          {t('groupMembers', { count: members.length > 0 ? members.length : '-' })}
          {/* {t('groupMembers')} */}
        </Typography>
      </Box>

      {/* Members List Section */}
      <Typography sx={{ mb: 1, color: isDarkTheme ? '#e0e0e0' : theme.palette.text.primary, fontSize: '15px' }}>
        {t('getGroupMembersText')}
      </Typography>
      <Divider sx={{ mb: 2, backgroundColor: isDarkTheme ? '#444' : '#ccc' }} />


      <List
        sx={{
          boxSizing: 'border-box',
          // maxHeight: "300px",
          // height:'auto',
          width: "100%", maxHeight: "calc(100vh - 430px)", overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor:
            AppTheme === "dark" ? "#3b4348 #111b21" : " #111b21",
          [theme.breakpoints.down("sm")]: {
            // maxHeight: "200px",
          },
          // backgroundColor: isDarkTheme ? '#1c1c1c' : '#fff',
        }}
      >
        {/* Add Members Tab */}
        <ListItem onClick={handleOpenDialog} sx={{
          border: `1px dashed ${isDarkTheme ? '#444' : '#ccc'}`, mb: 1, cursor: 'pointer', display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <ListItemAvatar>
            <Avatar sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}>
              <PersonAdd />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Add Members"
            primaryTypographyProps={{ fontSize: "17px" }}
            sx={{ color: isDarkTheme ? '#e0e0e0' : '#000', textAlign: i18n.language === 'ar' ? 'right' : 'left', }}
          />
        </ListItem>

        {members.map((member,index) => (
          <ListItem key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden',
                '&:hover .dropdown-icon': {
                  opacity: 1,
                },
              }}
            >
              <ListItemAvatar>
                <Avatar src={member.profileUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap', // Prevents text from wrapping
                      overflow: 'hidden', // Hides overflowing text
                      textOverflow: 'ellipsis', // Shows ellipsis at the end of overflowed text
                      fontSize: '17px',
                      direction: i18n.language === "ar" && (isTypingInArabic(member.userName) === true) ? 'rtl' : 'ltr',
                      textAlign: i18n.language === "ar" && (isTypingInArabic(member.userName) === true) ? 'right' : 'left',
                      textAlign: i18n.language === "ar" ? 'end' : 'start'
                    }}
                  // This also ensures text doesn't wrap to the next line
                  >
                    {member.userId === userId ? "You" : member.userName}
                  </Typography>
                }
                primaryTypographyProps={{ fontSize: '17px' }}
                sx={{
                  color: isDarkTheme ? '#e0e0e0' : '#000',
                  display:"flex",
                  flexDirection:"column",
                }}
              />
              {member.userRole == 'ADMIN' && <Box sx={{
                paddingX:"4px",
                background:AppTheme=='dark'?"#2A3942":"#E7FCE3",
                color:AppTheme=='dark'?"#E9EDEF":"#218B4D"

              }}> <Typography> Group admin
                </Typography></Box>}

             {
           member.userId != userId &&  isAdmin && 
             <Box
                className="dropdown-icon"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget)
                  setSelectedId(member)
                }}
                sx={{
                  position: 'absolute',
                  top: member.userRole == 'ADMIN'? '30px':"12px",
                  right: i18n.language === "ar" ? 'inherit' : '8px', // Right side for non-RTL
                  left: i18n.language === "ar" ? '8px' : 'inherit', // Left side for RTL (Arabic)
                  background: 'transparent',
                  opacity: 0,
                  transition: 'opacity 0.3s ease-in-out',
                  zIndex: 1000,
                  color: "#667781",
                  width: "30px",
                  height: "30px",
                 
                }}
              > 
                <RiArrowDropDownLine size={30} />
              </Box>}

             {selectedId && <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  sx: {
                    boxShadow: "none !important",
                    width: "180px !important",
                    backgroundColor: AppTheme === "dark" ? "#182229 !important" : "#FFFFFF !important", // white menu background
                    boxShadow:"1px red",
                    fontSize:"14.5px"
                  },
                }}
                BackdropProps={{
                  sx: {
                    backgroundColor: "transparent !important", // transparent backdrop
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={()=>makeGroupAdmin(selectedId)}>
                  <Box sx={{ color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", fontSize:"14.5px" }}>
                   {selectedId.userRole == 'USER'? t('makeGroupAdmin'):t('dismissAsAdmin')}
                  </Box>
                </MenuItem>
                <MenuItem onClick={()=>RemoveFromGroup(chatRoomId,selectedId.userId,userId)}>
                  <Box sx={{ color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", fontSize:"14.5px",display:'flex'}} >
                  {t('remove')}
                  </Box>
                </MenuItem>
              </Menu>}


            </Box>
          </ListItem>
        ))}



      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
          color: AppTheme == 'dark' ? '#D35565' : '#EA0038',
          // lineHeight:0,
        }}

        // onClick={handleExistGroup}
        onClick={()=>setExitGroupDialog(true)}
      >
       
        <ListItemAvatar>
          <LogoutIcon />
        </ListItemAvatar>
        
        {/* <IconButton>
        <LogoutIcon  />
      </IconButton> */}
        <ListItemText
          primary={
            <Typography
              sx={{
                whiteSpace: 'nowrap', // Prevents text from wrapping
                overflow: 'hidden', // Hides overflowing text
                textOverflow: 'ellipsis', // Shows ellipsis at the end of overflowed text
                fontSize: '17px',
                direction: i18n.language === "ar" ? 'rtl' : 'ltr',
                textAlign: i18n.language === "ar" ? 'right' : 'left',
                // lineHeight:'0px',
                color: AppTheme == 'dark' ? '#D35565' : '#EA0038'
              }}
            // This also ensures text doesn't wrap to the next line
            >
              {/* {member.userId===userId?"You":member.userName} */}
              {isActiveMember ? 'Exit group' : "Delete group"}
            </Typography>
          }
          primaryTypographyProps={{ fontSize: '17px' }}
          sx={{
            color: isDarkTheme ? '#e0e0e0' : '#000',
          }}
        />
      </Box>
      {/* <Dialog 
      open={exitGroupDialog}
      onClose={()=>{ setExitGroupDialog(false)}}
      PaperProps={{
        style: {
          borderRadius: '1px', // Ensure this applies to the Paper component
          backgroundColor: AppTheme === 'dark' ? '#202c33' : '#f0f2f5',
        },
      }}
      >
        <Box sx={{display:"flex",flexDirection:"column",alignItems:"center", margin:"10px", backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
        color: AppTheme === "light" ? "#111b21" : "#e9edef",}}>
        <Box sx={{color:AppTheme=='dark'?"#ffffff":"#000000",}}>
        Do you want to exit the group?
        </Box>
        <Box sx={{display:"flex",justifyContent:"space-between",marginTop:"40px" , width:"80%"}}>

        <Button onClick={()=>{ setExitGroupDialog(false)}} >No</Button>
        <Button  sx={{color:"#EA0038"}} onClick={handleExistGroup}>Yes</Button>
        </Box>
        </Box>
  
      </Dialog> */}

   <ExitGroupDialog exitGroupDialog={exitGroupDialog} setExitGroupDialog={setExitGroupDialog} handleExistGroup={handleExistGroup} AppTheme={AppTheme}/>
    </Paper>
  );
};

export default GroupInfoPage;
